// app/javascript/controllers/search_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "forumContainer"]
  
  connect() {
    this.debounceTimeout = null
    this.setupClearButton()
    
    if (this.inputTarget.value) {
      const clearButton = this.element.querySelector('.clear-search-button')
      clearButton.style.display = 'block'
    }
  }

  handleSubmit(event) {
    event.preventDefault()
  }
  
  setupClearButton() {
    const existingButton = this.element.querySelector('.clear-search-button')
    if (existingButton) {
      existingButton.remove()
    }
    
    const clearButton = document.createElement('button')
    clearButton.type = 'button'
    clearButton.className = 'clear-search-button'
    clearButton.innerHTML = `
      <span class="material-icons">clear</span>
    `
    clearButton.style.display = 'none'
    
    this.inputTarget.parentNode.appendChild(clearButton)
    clearButton.addEventListener('click', this.clearSearch.bind(this))
  }
  
  handleInput(event) {
    clearTimeout(this.debounceTimeout)
    const query = event.target.value.toLowerCase()
    
    const clearButton = this.element.querySelector('.clear-search-button')
    clearButton.style.display = query.length > 0 ? 'block' : 'none'
    
    if (query.length === 0) {
      this.showAllFruits()
      return
    }
    
    this.debounceTimeout = setTimeout(() => {
      this.filterFruitsList(query)
    }, 300)
  }
  
  filterFruitsList(query) {
    let hasMatches = false
    
    this.forumContainerTargets.forEach(container => {
      const forumName = container.querySelector('.forum-name').textContent.toLowerCase()
      const forumPart = forumName.split(' forum')[0].trim()
      const matches = forumPart.includes(query)
      
      if (matches) {
        hasMatches = true
        container.classList.remove('hidden')
      } else {
        container.classList.add('hidden')
      }
    })
    
    this.toggleNoResultsMessage(hasMatches)
  }
  
  showAllFruits() {
    this.forumContainerTargets.forEach(container => {
      container.classList.remove('hidden')
    })
    
    const noResultsEl = this.element.querySelector('.no-results-message')
    if (noResultsEl) {
      noResultsEl.remove()
    }
  }
  
  toggleNoResultsMessage(hasMatches) {
    const existingMessage = this.element.querySelector('.no-results-message')
    
    if (!hasMatches) {
      if (!existingMessage) {
        const message = document.createElement('div')
        message.className = 'no-results-message'
        message.textContent = 'No matching forums found'
        this.element.querySelector('.forums-grid').appendChild(message)
      }
    } else if (existingMessage) {
      existingMessage.remove()
    }
  }
  
  clearSearch() {
    this.inputTarget.value = ''
    const clearButton = this.element.querySelector('.clear-search-button')
    clearButton.style.display = 'none'
    this.showAllFruits()
  }
  
  handleClickOutside(event) {
    if (!this.element.contains(event.target)) {
      this.clearSearch()
    }
  }
}